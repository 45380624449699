import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import "./BulkUpload.css";
import FileCard from "./FileCard/FileCard";
import UploadButton from "./UploadButton/UploadButton";
import { TrainingApi } from "../../api/Training/Training";
import { useDispatch } from "react-redux";
import { setLoader } from "../../utils/setLoader";
import FileUpload from "../../assets/icons/fileupload.png"

const { Dragger } = Upload;

const BulkUpload = () => {
    const dispatch = useDispatch()
    const [fileList, setFileList] = useState([]);

    const handleDelete = (fileToDelete) => {
        const newFileList = fileList.filter(file => file.uid !== fileToDelete.uid);
        setFileList(newFileList);
        message.success(`${fileToDelete.name} has been deleted.`);
    };

    const props = {
        name: "file",
        multiple: true,
        accept: ".csv",
        onChange(info) {
            const { status } = info.file;
            if (status !== "uploading") {
                console.log(info.file, info.fileList);
            }
            if (status === "done") {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === "error") {
                message.error(`${info.file.name} file upload failed.`);
            }
            // Update the state only when the files are being added
            setFileList(info.fileList.filter((file) => !!file.status));
        },
        customRequest({ file, onSuccess, onError, onProgress }) {
            // You can perform actual upload here if needed or just simulate the upload
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        },
        showUploadList: false
    };

    // Function to handle file upload to dummy endpoint
    const handleUpload = async () => {
        if (!fileList.length) {
            message.error("No File Uploaded");
        }
        else {

            message.success("Files queued for upload ⏳");
            setLoader({ dispatch, value: true });
            const formData = new FormData();
            fileList.forEach((file) => {
                formData.append("files", file.originFileObj);
            });

            //await bulkUploadApi(formData, setFileList, user.accessToken)
            await TrainingApi(formData, setFileList, dispatch)
        }
    };


    const isFileListEmpty = fileList.length === 0;

    return (
        <div className="h-full w-full flex flex-col gap-10">

      
        <div className={`bulk-upload-container flex gap-6 items-start w-full justify-center `}>
            <div className="overflow-hidden relative flex flex-col justify-center w-[40%] items-center gap-4 ml-8">
                <Dragger className="w-[100%] main-uploader" {...props} fileList={fileList}>
                    <p className="ant-upload-drag-icon flex justify-center">
                        <UploadOutlined style={{ color: "black" }} />

                    </p>
                    <p className="ant-upload-text font-outfit">
                        Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint font-outfit">
                        Support for a single or bulk upload. Strictly prohibited from
                        uploading company data or other banned files.
                    </p>
                </Dragger>
               
            </div>
            {!isFileListEmpty && (
                <div className="w-[33%] h-80 h-max-80 rounded-md flex bg-[#fafafa] justify-start flex-col items-center pt-10 pb-10 gap-2 overflow-y-auto shadow-md">
                    {fileList.map((file) => (
                        <FileCard
                            key={file.uid}
                            fileName={file.name}
                            onDelete={() => handleDelete(file)}
                        />
                    ))}
                </div>
            )}
        </div>
        <div className="flex w-full justify-center">

         {fileList.length>0 && (
             <UploadButton onClick={() => handleUpload()} />
            )}
          </div>
            </div>
    );
};

export default BulkUpload;