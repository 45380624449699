import React from 'react'

const Suggestions = ({ onSuggestionClick }) => {
    const placeholders = [
        "Tell me about the",
        "What activities",
        "What systems are",
        "What things are"
    ]
    const suggestions = [
        "common pain points of the organization?",
        "take most of the employees' time?",
        "interacted with the most?",
        "currently working well in the job?"
    ];

    return (
        <div className="flex flex-wrap justify-center gap-4 mb-12">
            {suggestions.map((suggestion, index) => (
                <div 
                    key={index} 
                    className="w-[45%] min-w-[200px] border border-solid border-black rounded-lg p-4 cursor-pointer transition-all duration-300 hover:bg-gray-100 hover:shadow-md"
                    onClick={() => onSuggestionClick(placeholders[index] + " " + suggestion)}
                >
                    <div className="font-outfit font-normal text-md tracking-wide">
                        {placeholders[index]}
                    </div>
                    <div className="font-outfit font-light text-md tracking-wide">{suggestion}</div>
                </div>
            ))}
        </div>
    )
}

export default Suggestions