import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    componentMounted: "Chat",
    currentMessage: "",
    currentResponse: "",
    chatToggle: false
  };

  export const chatbotStatesSlice = createSlice({
    name: "chatbotStates",
    initialState,
    reducers: {
      setComponentMounted: (state, action) => {
        state.componentMounted = action.payload.componentMounted ;
      },
      clearComponentMounted: (state) => {
        state.componentMounted = initialState.componentMounted;
      },
      setCurrentMessage: (state, action) => {
        state.currentMessage = action.payload.currentMessage; 
      },
      clearCurrentMessage: (state) => {
        state.currentMessage = initialState.currentMessage; 
      },
      setCurrentResponse: (state, action) => {
        state.currentResponse = action.payload.currentResponse; 
      },
      clearCurrentResponse: (state) => {
        state.currentResponse = initialState.currentResponse; 
      },
      setChatToggle: (state, action) => {
        state.chatToggle = action.payload.chatToggle;
      },
      clearChatToggle: (state) => {
        state.chatToggle = initialState.chatToggle;
      }
     
    },
  });
  
  
  export const { 
    setComponentMounted, 
    clearComponentMounted,
    setCurrentMessage,
    clearCurrentMessage,
    setCurrentResponse,
    clearCurrentResponse,
    setChatToggle,
    clearChatToggle
  } = chatbotStatesSlice.actions;
  
  export default chatbotStatesSlice.reducer;