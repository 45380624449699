import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    code: "",
    isVerified: false,
};

export const codeSlice = createSlice({
    name: "code",
    initialState,
    reducers:{
        setCode: (state, action) => {
            state.code = action.payload.code;
        },
        setVerified: (state, action) => {
            state.isVerified = action.payload.isVerified;
        },
        resetCode: (state) => {
            state.code = "";
            state.isVerified = false;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setCode,setVerified,resetCode} = codeSlice.actions;

export default codeSlice.reducer;
