// message-utils.jsx
import { motion } from "framer-motion";


const formatMessage = (text) => {
    return text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
};

// Function to render the message with animation if the sender is "User"
const renderAnimatedMessage = (text) => {
    const words = text.split(" ");
    return words.map((word, i) => (
        <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
                duration: 0.25,
                delay: i / 10
            }}
            key={i}
        >
            {word}{" "}
        </motion.span>
    ));
};

const formatHeading = (heading) => {
    const emoji = getEmojiForHeading(heading);
    const formattedHeading = heading
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
        return [
            emoji,
            <span key="spacer" style={{ marginLeft: '0.625rem' }} />,
            formattedHeading
        ];
};

const getEmojiForHeading = (heading) => {
    switch (heading) {
        case 'overview_of_what_is_working_well':
            return '✅';
        case 'overview_of_pain_points':
            return '🔴';
        case 'overview_of_ideas_to_alleviate_pain_points':
            return '💡';
        case 'overview_of_systems_and_tools_based_on_usage':
            return '🛠️';
        case 'responded_to_the_survey':
            return '📝';
        case 'top_three_pain_points':
            return '❗️';
        default:
            return '📌';
    }
};

// Exporting the functions
export { formatMessage, renderAnimatedMessage, formatHeading };