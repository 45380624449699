import { createSlice } from "@reduxjs/toolkit";
import io from "socket.io-client";

export const socketIoSlice = createSlice({
  name: "socketIo",
  initialState: {
    isConnected: false,
    socketId: null,
  },

  reducers: {
    setConnected(state, action) {
      state.isConnected = action.payload;
    },
    setSocketId(state, action) {
      state.socketId = action.payload;
    },
    clearSocket(state) {
      state.isConnected = false;
      state.socketId = null;
    },
  },
});

export const { setConnected, setSocketId, clearSocket } = socketIoSlice.actions;

export const selectSocketConnected = (state) => state.socketIo.isConnected;
export const selectSocketId = (state) => state.socketIo.socketId;

let socket = null;

export const initializeSocket = () => (dispatch) => {
  socket = io(`${process.env.REACT_APP_BACKEND_URL}/chat`, {
    transports: ["websocket"],
  });

  socket.on("connect", () => {
    dispatch(setConnected(true));
    dispatch(setSocketId(socket.id));
    console.log("Socket connected.");
  });

  socket.on("disconnect", () => {
    dispatch(setConnected(false));
    dispatch(setSocketId(null));
    console.log("Socket disconnected.");
  });
};

export const disconnectSocket = () => (dispatch) => {
  if (socket) {
    socket.emit("end_session");

    socket.once("session_ended", () => {
      socket.disconnect();
      dispatch(clearSocket());
      console.log("Socket disconnected after ending session.");
    });
  }
};

export const getSocket = () => socket;

export default socketIoSlice.reducer;
