import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Settings from "./pages/Settings/Settings";
import ReportViewer from "./pages/ReportViewer/ReportViewer";
import Home from "./pages/Home/Home";
import { CodeModal } from "./components/CodeModal/CodeModal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReportGenerator from "./pages/ReportGenerator/ReportGenerator";
import { Chat } from "./pages/Chat/Chat";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CodeModal />
        <ToastContainer />
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <main>
                  <Home />
                </main>
              }
            />

            <Route path="/chat" element={<Chat />} />
            <Route path="/report" element={<ReportGenerator />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/insights" element={<ReportViewer />} />
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
