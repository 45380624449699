import React, { useState, useEffect } from 'react'

const SectionSwitcher = ({sections, onSectionChange}) => {

    const [activeSection, setActiveSection] = useState(sections[0]);
    useEffect(() => {
        // Set the first non-disabled section as active by default
        const firstEnabledSection = sections.find(section => !section.disabled);
        if (firstEnabledSection) {
          setActiveSection(firstEnabledSection.name);
        }
      }, [sections]);
    
      const handleSectionClick = (sectionName) => {
        setActiveSection(sectionName);
        if (onSectionChange) {
          onSectionChange(sectionName);
        }
      };

  return (
    <div className="flex justify-center items-center bg-[#ebebeb] p-1 rounded-lg gap-4 shadow-md">
      {sections.map((section) => (
        <button
          key={section.name}
          className={`px-4 py-2 rounded-md transition-colors duration-300 focus:outline-none w-[12rem] flex gap-8
            ${section.disabled 
              ? 'bg-transparent text-gray-500 cursor-not-allowed'
              : activeSection === section.name
                ? 'bg-[#1C46F3] text-white bg-opacity-100 backdrop-filter backdrop-blur-lg border border-white border-opacity-30 shadow-md'
                : 'bg-transparent text-gray-500 hover:bg-gray-300'
            }`}
          onClick={() => !section.disabled && handleSectionClick(section.name)}
          disabled={section.disabled}
        >
          {section.url && (
            <img src={section.url} style={{width: "20px"}}/>
          )}
          {section.name}
        </button>
      ))}
    </div>
  )
}

export default SectionSwitcher