import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageLoader: false,
  inputLoader: false,
  progress: 0,
  progressMessage: ''
};

export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setPageLoader: (state, action) => {
      state.pageLoader = action.payload.pageLoader;
    },
    clearPageLoader: (state) => {
      state.pageLoader = initialState.pageLoader;
    },
    setInputLoader: (state, action) => {
      state.inputLoader = action.payload.inputLoader;
    },
    clearInputLoader: (state) => {
      state.inputLoader = initialState.inputLoader;
    },
    setProgress: (state, action) => {
      state.progress = action.payload.progress;
    },
    clearProgress: (state) => {
      state.progress = initialState.progress;
    },
    setProgressMessage: (state, action) => {
      state.progressMessage = action.payload.progressMessage;
    },
    clearProgressMessage: (state) => {
      state.progressMessage = initialState.progressMessage;
    }

  },
});

// Action creators are generated for each case reducer function
export const {
  setPageLoader,
  clearPageLoader,
  setInputLoader,
  clearInputLoader,
  setProgress,
  clearProgress,
  setProgressMessage,
  clearProgressMessage
} = loaderSlice.actions;

export default loaderSlice.reducer;
