import axios from "axios";

const apiUrl = process.env.REACT_APP_BACKEND_URL;


export const verifyCode = async (code) => {
    try {
        const response = await axios.post(`${apiUrl}/verifyCode`, { code });
        const data = await response.data;
        if (response.status !== 200) {
            throw new Error(data.message || "Failed to Verify Code");
        }
        return data;
    } catch (error) {
        console.error("Error verifying Code:", error);
        throw error;
    }
}


