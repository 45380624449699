import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import userReducer from "./slices/userSlice";
import loaderReducer from './slices/loaderSlice';
import chatbotStatesReducer from "./slices/chatbotStatesSlice";
import menuReducer from "./slices/menuSlice";
import socketIoReducer from "./slices/socketioSlice";
import codeReducer from "./slices/codeSlice";

const persistConfig = {
    key: "root",
    storage,
    blacklist: ['socketIo'], // Exclude socketIo from persistence
    timeout: 2000, // Set a timeout (in milliseconds)
};

const rootReducer = combineReducers({
    user: userReducer,
    loader: loaderReducer,
    chatbotStates: chatbotStatesReducer,
    menu: menuReducer,
    socketIo: socketIoReducer,
    code:codeReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export const persistor = persistStore(store);

// Add this line to export the store as default
export default store;