import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import UploadButton from '../../components/BulkUpload/UploadButton/UploadButton';
import { Table } from 'antd';

const ReportViewer = () => {
    const location = useLocation();
    const insights = location.state?.insights;
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/report');
    };

    if (!insights) {
        return (
            <div className='flex flex-col justify-center items-center h-screen gap-4'>
                <p>No insights available. Please click below to generate insights</p>
                <UploadButton label={"Generate Insights"} onClick={handleClick} />
            </div>
        );
    }

// Dynamically filter columns based on available data
const availableColumns = [
    !insights.roles && {
        title: 'Business Area', // Always include Business Area
        dataIndex: 'business_area',
        key: 'business_area',
        render: (text, record) => {
            if (record.isArea) {
                return { children: <strong>{text}</strong>, props: { colSpan: 5 } };
            }
            return text;
        }
    },
    insights.roles && {
        title: 'Role',
        dataIndex: 'role_title',
        key: 'role_title',
        render: (text, record) => {
            if (record.isArea) {
                return { children: <strong>{text}</strong>, props: { colSpan: 5 } };
            }
            return <div style={{ maxHeight: '400px', overflow: 'auto' }}>{text}</div>;
        }
    },
    insights.roles && {
        title: 'Day in the life',
        dataIndex: 'role_description',
        key: 'role_description',
        render: (text, record) => {
            if (record.isArea) {
                return { children: <strong>{text}</strong>, props: { colSpan: 5 } };
            }
            return <div style={{ maxHeight: '400px', overflow: 'auto' }}>{text}</div>;
        }
    },
    insights.role_specific_insights && {
        title: 'Jobs to be Done',
        dataIndex: 'jobs_to_be_done',
        width: 400,
        key: 'jobs_to_be_done',
        render: (text, record) => {
            if (record.isArea) return { children: null, props: { colSpan: 0 } };
            return <div style={{ maxHeight: '400px', overflow: 'auto' }}>{text}</div>;
        }
    },
    insights.pain_points_and_ideas && insights.pain_points_and_ideas.some(item => Array.isArray(item.pain_points)) &&  {
        title: 'Pain Points',
        dataIndex: 'pain_points',
        key: 'pain_points',
        width: 200,
        render: (text, record) => {
            if (record.isArea) return { children: null, props: { colSpan: 0 } };
            return <div style={{ maxHeight: '400px', overflow: 'auto' }}>{text}</div>;
        }
    },
    insights.pain_points_and_ideas && insights.pain_points_and_ideas.some(item => Array.isArray(item.ideas)) && {
        title: 'Ideas',
        dataIndex: 'ideas',
        key: 'ideas',
        width: 200,
        render: (text, record) => {
            if (record.isArea) return { children: null, props: { colSpan: 0 } };
            return <div style={{ maxHeight: '400px', overflow: 'auto' }}>{text}</div>;
        }
    }
    
].filter(Boolean); // Remove undefined columns

const dataSource = insights.business_areas.flatMap((area) => {
    const painPointsAndIdeas = insights.pain_points_and_ideas?.find(painPoint => painPoint.id === area.id);
    const jobsToBeDoneForArea = insights.role_specific_insights?.filter(insight => insight.id === area.id);
    const roles = insights.roles?.filter(role => role.id === area.id)
        .map((role, idx) => {
            const jobDetails = insights.role_specific_insights?.find(insight => insight.id === role.id);
            return {
                key: `${area.id}-role-${idx}`,
                business_area: '',
                role_title: <div className='tracking-wide'>{role.role_title}</div>,
                role_description: <div className='flex items-start justify-start font-outfit tracking-wide'>{role.role_description}</div>,
                jobs_to_be_done: jobDetails ? (
                    <ul className='list-disc list-inside'>
                        {jobDetails.jobs_to_be_done?.map((job, i) => <li key={i} className='tracking-wide'>{job}</li>)}
                    </ul>
                ) : null,
                pain_points: painPointsAndIdeas?.pain_points ? (
                    <ul className='list-disc list-inside'>
                        {painPointsAndIdeas.pain_points.map((point, i) => <li key={i} className='tracking-wide'>{point}</li>)}
                    </ul>
                ) : null,
                ideas: painPointsAndIdeas?.ideas ? (
                    <ul className='list-disc list-inside'>
                        {painPointsAndIdeas.ideas.map((idea, i) => <li key={i} className='tracking-wide'>{idea}</li>)}
                    </ul>
                ) : null,
            };
        }) || [];

    const areaJobDetailsRow = (!roles.length && (jobsToBeDoneForArea?.length || painPointsAndIdeas)) ? {
        key: `area-${area.id}-jobs`,
        business_area: (
            <div className='flex flex-col tracking-wide font-outfit'>
                <span className='font-bold'>{area.business_area_title}</span>
                <span className='font-normal italic'>{area.business_area_description}</span>
            </div>
        ),
        role_title: '',
        role_description: '',
        jobs_to_be_done: jobsToBeDoneForArea?.length ? (
            <ul className='list-disc list-inside'>
                {jobsToBeDoneForArea.map((jobDetail, i) =>
                    jobDetail.jobs_to_be_done?.map((job, j) => <li key={j} className='tracking-wide'>{job}</li>)
                )}
            </ul>
        ) : null,
        pain_points: painPointsAndIdeas?.pain_points ? (
            <ul className='list-disc list-inside'>
                {painPointsAndIdeas.pain_points.map((point, i) => <li key={i} className='tracking-wide'>{point}</li>)}
            </ul>
        ) : null,
        ideas: painPointsAndIdeas?.ideas ? (
            <ul className='list-disc list-inside'>
                {painPointsAndIdeas.ideas.map((idea, i) => <li key={i} className='tracking-wide'>{idea}</li>)}
            </ul>
        ) : null
    } : null;

    const areaRow = {
        key: `area-${area.id}`,
        // business_area: (
        //     <div className='flex flex-col tracking-wide font-outfit'>
        //         <span className='font-bold'>{area.business_area_title}</span>
        //         <span className='font-normal italic'>{area.business_area_description}</span>
        //     </div>
        // ),
        role_title: (
            <div className='flex flex-col tracking-wide font-outfit'>
                <span className='font-bold'>{area.business_area_title}</span>
                <span className='font-normal italic'>{area.business_area_description}</span>
            </div>
        ),
        role_description: '',
        jobs_to_be_done: '',
        pain_points: '',
        ideas: '',
        isArea: true
    };

    return areaJobDetailsRow ? [areaJobDetailsRow] : [areaRow, ...roles];
});
    



    return (
        <div className='h-screen flex flex-col font-outfit'>
            <Header title="Report Viewer" />
            <div className='flex-1 overflow-y-auto px-40 mt-6'>
                <Table
                    columns={availableColumns}
                    dataSource={dataSource}
                    pagination={false}
                    bordered
                    size="middle"
                    className='mb-10'
                />
            </div>
        </div>
    );
};

export default ReportViewer;