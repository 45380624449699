import React, { useState } from 'react';

const SelectSection = ({ img_source, selected_img_source, card_title, card_description, isSelected, onClick }) => {
  return (
    <div 
      className={`px-8 py-6 flex flex-col font-outfit border-2 border-solid border-outline rounded items-center gap-2 cursor-pointer 
      ${isSelected ? 'border-blueBg' : 'border-outline'}`}
      onClick={onClick}
    >
      <img 
        src={isSelected ? selected_img_source : img_source}
        style={{ width: "32px" }}
      />
      <p className={`${isSelected ? 'text-blueBg' : 'text-gray-400'} font-semibold font-poppins`}>
        {card_title}
      </p>
      <p className={`text-xs text-center ${isSelected ? 'text-blueBg' : 'text-gray-400'}`}>
        {card_description}
      </p>
    </div>
  );
}

export default SelectSection;
