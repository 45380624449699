import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "./InputSection.css";
import arrowUpIcon from "../../assets/icons/arrow-up-icon.svg";

const InputSection = ({ sendMessage, inputValue, setInputValue }) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputLoader = useSelector((state) => state.loader.inputLoader);
  const textareaRef = useRef(null);

  useEffect(() => {
    adjustTextareaHeight();
  }, [inputValue]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      const newHeight = Math.min(textarea.scrollHeight, 360);
      textarea.style.height = `${newHeight}px`;
    }
  };

  const handleSetInputValue = (e) => {
    setInputValue(e.target.value);
    adjustTextareaHeight();
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey && !inputLoader && inputValue !== "") {
      e.preventDefault();
      sendMessage(inputValue);
    }
  };

  const isButtonDisabled = inputLoader || inputValue === "";

  return (
    <div className="flex flex-col justify-center relative gap-4">
      <div className={`relative flex justify-between rounded-xl w-[100%] m-auto input-field bg-white transition-all duration-200 ${isFocused ? 'shadow-lg border-2 border-gray-300' : 'border border-gray-300'}`} style={{ maxHeight: '400px' }}>
        <textarea
          ref={textareaRef}
          placeholder="Ask Anything..."
          value={inputValue}
          onChange={handleSetInputValue}
          onKeyDown={handleEnterKeyPress}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className="autosize-textarea rounded-xl px-4 py-6 overflow-y-auto custom-scrollbar focus:outline-none text-black text-md tracking-wide bg-white w-full"
          style={{ height: '90%', minHeight: '40px', maxHeight: '300px', resize: 'none' }}
        />

        <button
          onClick={() => {
            if (!isButtonDisabled) {
              sendMessage(inputValue);
            }
          }}
          disabled={isButtonDisabled}
          className={`bg-white cursor-pointer ${isButtonDisabled ? "opacity-50 cursor-not-allowed" : ""
            }  py-2 px-4 text-lg font-normal rounded-xl`}
        >
          <img className={`w-[15px] h-[15px]`} src={arrowUpIcon} alt="send" />
        </button>
      </div>
    </div>
  );
};

export default InputSection;
